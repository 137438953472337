import { IconRightArrow12pxRegular } from "@pionex-web-kit/icons";
import { AnnouncementListItem } from "landings/Messages/api/interface";
import { memo } from "react";
import useDateFormatter from "commonUse/useDateFormatter";
import useTheme from "commonUse/useTheme";
import { ThemeName } from "state/ThemeData";
import cs from "./cs.m.less";
interface ItemProps {
    onClick: (id: string) => void;
    item: AnnouncementListItem;
    showBline: boolean;
    idx: number;
}

const AnnounceItem = ({ onClick, showBline, item: { timestamp, summary, content, title, location } }: ItemProps) => {
    const dateFormatter = useDateFormatter();
    const theme = useTheme();
    const isRead = false;

    return (
        <div className={`pl-36px bg-bg-modal ${theme.name === ThemeName.light ? cs.newsItemBg : cs.newsItemDarkBg}`}>
            <div className={`pr-[38px] py-12px  ${showBline ? `${cs.newsItemBorderLine} border-divider` : ""}`} onClick={() => onClick(location)}>
                <div className="relative">
                    <div className={`flex font-m text-sm mb-4px text-accent ${isRead ? "text-accent-weak" : "text-accent"}`}>{title}</div>
                    {(summary || content) && (
                        <div className={`font-r text-sm mb-6px ${cs.textOverflow} ${isRead ? "text-accent-sub" : "text-accent-weak"}`}>{summary || content}</div>
                    )}
                    <div className="font-r text-sm text-[#a1a2aa]">{dateFormatter.year_minute(timestamp)}</div>
                    <IconRightArrow12pxRegular size={12} className="text-accent-sub absolute right-[-20px] top-[calc(50%-6px)]" />
                </div>
            </div>
        </div>
    );
};

export default memo(AnnounceItem);
