import { IconRead16pxRegular, IconRightArrow12pxRegular, IconAnnouncemen20pxBlodS, IconInformation20pxBlodS } from "@pionex-web-kit/icons";
import { useTranslators } from "commonUse/Locale";
import classNames from "classnames";
import { notificationReadAllFn, notificationReadItemFn, useGetAnnouncementList, useGetNotificationList } from "landings/Messages/hook";
import { useCallback } from "react";
import { useHistory } from "react-router";
import NoData from "src/components-v2/NoData";
import useAccountInfo from "commonUse/useAccountInfo";
import AnnounceItem from "./announceItem";
import cs from "./cs.m.less";
import NewsItem from "./NewsItem";

interface NewsTabProps {
    count: number;
    announcementReadyState: boolean;
}

const NewsTab = ({ count, announcementReadyState }: NewsTabProps) => {
    const { $st, locale } = useTranslators();
    const accountInfo = useAccountInfo();
    const notificationLimit = !!accountInfo.userId ? 3 : 0; // 系统消息
    const AnnouncementLimit = !!accountInfo.userId ? 1 : 4; // 公告
    const { data: notificationList } = useGetNotificationList(notificationLimit);
    const { data: announcementList } = useGetAnnouncementList(AnnouncementLimit);
    const history = useHistory();

    const handleOverview = useCallback(() => {
        history.push("/message/system");
    }, [history]);

    const announcementOverview = useCallback(() => {
        let notificationUrl = "https://pionexus.zendesk.com/hc/en-us/categories/4430092749081-Announcements";
        new URL(notificationUrl);
        window.open(notificationUrl);
    }, []);

    const handleItemClick = useCallback(
        (id) => {
            history.push("/message/system");
            notificationReadItemFn(id);
            // report("message_center_message_click");
        },
        [history],
    );

    const announcementItemClick = useCallback(
        (location) => {
            try {
                if (accountInfo.userId) {
                    history.push("/message/announcement");
                } else {
                    new URL(location);
                    window.open(location);
                }

                // report("message_center_announcement_click");
            } catch (err) {}
        },
        [accountInfo.userId, history],
    );

    const handleReadAll = useCallback(() => {
        notificationReadAllFn();
    }, []);

    return (
        <div className="bg-bg-modal w-[400px] rounded-[12px] overflow-hidden">
            <div className="pl-[8px] text-accent mb-[16px]">{$st("header_message_center")}</div>
            {announcementList.length > 0 && (
                <>
                    <div className="flex items-center justify-between pl-[8px] pr-[16px] my-[4px]">
                        <div className="flex mr-8px font-m">
                            <IconAnnouncemen20pxBlodS size={20} className="flex-shrink-0 mr-[8px]" />
                            <div className="text-sm text-accent text-[14px] flex items-center">
                                {$st("message_announcement_tab_title")}
                                {!announcementReadyState && <span className="h-[6px] w-[6px] ml-[8px] rounded-[3px] bg-[#EB4747]"></span>}
                            </div>
                        </div>
                    </div>
                    <div className={classNames("max-h-[590px] overflow-y-auto", notificationLimit > 0 ? `${cs.newsItemBorderLine} border-divider` : "")}>
                        {announcementList.slice(0, AnnouncementLimit).map((item, idx) => {
                            return <AnnounceItem showBline={AnnouncementLimit !== idx + 1} onClick={announcementItemClick} key={`${item.timestamp}`} item={item} idx={idx} />;
                        })}
                        {!accountInfo.userId && (
                            <div className={classNames("flex items-center pl-[36px] mt-6px", cs.readAllWrap)}>
                                <span className={classNames("font-m text-sm mr-[3px] text-accent-sub", cs.readAllText)} onClick={announcementOverview}>
                                    {$st("contest_view_all")}
                                </span>
                                <IconRightArrow12pxRegular size={12} className={classNames("text-accent-sub", cs.readAllIcon)} />
                            </div>
                        )}
                    </div>
                </>
            )}
            {!!accountInfo.userId && notificationList.length > 0 && (
                <>
                    <div className={classNames("flex items-center justify-between pl-[8px] pr-[16px] mt-[16px] mb-[4px]")}>
                        <div className="flex mr-8px font-m">
                            <IconInformation20pxBlodS size={20} className="flex-shrink-0 mr-[8px]" />
                            <div className="text-sm text-accent text-[14px] flex items-center">
                                {$st("message_system_tab_title")}
                                {count > 0 && (
                                    <span className="h-[21px] leading-[21px] text-white ml-[5px] rounded-[10px] px-[6px] pt-[0px] pb-[2px] bg-[#EB4747] scale-[.67]">{count}</span>
                                )}
                            </div>
                        </div>
                        <div className={`font-m text-sm text-text-300 ${cs.readAllWrap}`} onClick={handleReadAll}>
                            <IconRead16pxRegular size={16} className={classNames("text-accent-sub visible align-[-2px] mr-[5px]", cs.readAllIcon)} />
                            <span className={classNames(cs.readAllText)}>{$st("message_all_read_text")}</span>
                        </div>
                    </div>
                    <div className="max-h-[590px] overflow-y-auto rounded-b-[12px]">
                        {notificationList.slice(0, notificationLimit).map((item, idx) => (
                            <NewsItem onClick={handleItemClick} showBline={notificationLimit !== idx + 1} key={`${item.message_id}_${item.read_timestamp}`} item={item} idx={idx} />
                        ))}
                        <div className={classNames("flex items-center pl-[36px] mt-6px", cs.readAllWrap)}>
                            <span className={classNames("font-m text-sm mr-[3px] text-accent-sub", cs.readAllText)} onClick={handleOverview}>
                                {$st("contest_view_all")}
                            </span>
                            <IconRightArrow12pxRegular size={12} className={classNames("text-accent-sub", cs.readAllIcon)} />
                        </div>
                    </div>
                </>
            )}
            {announcementList.length === 0 && notificationList.length === 0 && <NoData className="mt-[20px] pb-[20px]" />}
        </div>
    );
};

export default NewsTab;
