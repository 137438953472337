import { IconRightArrow12pxRegular } from "@pionex-web-kit/icons";
import { NotificationListItem } from "landings/Messages/api/interface";
import { memo } from "react";
import useDateFormatter from "commonUse/useDateFormatter";
import useTheme from "commonUse/useTheme";
import { ThemeName } from "state/ThemeData";
import cs from "./cs.m.less";
interface ItemProps {
    onClick: (id: string) => void;
    showBline: boolean;
    item: NotificationListItem;
    idx: number;
}

const NewsItem = ({ onClick, showBline, item: { create_timestamp, content, title, message_id, read_timestamp } }: ItemProps) => {
    const dateFormatter = useDateFormatter();
    const theme = useTheme();
    const isRead = read_timestamp > 0;

    return (
        <div className={`pl-36px bg-bg-modal ${theme.name === ThemeName.light ? cs.newsItemBg : cs.newsItemDarkBg}`}>
            <div
                className={`pr-[38px] py-12px 
                ${showBline ? `${cs.newsItemBorderLine} border-divider` : ""}
            `}
                onClick={() => onClick(message_id)}
            >
                <div className="relative">
                    <div className={`flex font-m text-sm mb-4px text-accent ${isRead ? "text-accent-weak" : "text-accent"}`}>
                        {title}
                        {isRead ? null : <div className="bg-red flex-shrink-0 w-[8px] h-[8px] rounded-[8px] ml-[4px] mt-[6px]"></div>}
                    </div>
                    {content && (
                        <div className={`text-ellipsis font-r text-sm mb-6px ${cs.textOverflow} ${isRead ? "text-accent-sub" : "text-accent-weak"} ${cs.lineClamp2}`}>
                            {content}
                        </div>
                    )}
                    <div className="font-r text-sm text-[#a1a2aa]">{dateFormatter.year_minute(create_timestamp)}</div>
                    <IconRightArrow12pxRegular size={12} className="text-accent-sub absolute right-[-20px] top-[calc(50%-6px)]" />
                </div>
            </div>
        </div>
    );
};

export default memo(NewsItem);
